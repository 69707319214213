<template>
  <div class="page">
    <div class="page__title">
      {{ getModuleConfig("redeem_confirm_page.form_title") }}
    </div>
    <div
      class="page__desc"
      v-html="getModuleConfig('redeem_confirm_page.form_desc')"
    ></div>
    <!-- <div  class="page__content" v-if="getModuleConfig('redeem_confirm_page.content')" v-html="getModuleConfig('redeem_confirm_page.content')"></div> -->
    <div v-if="couponType == '1'">
      <QRCodeScanner @onScan="onScan" />
    </div>
    <div v-if="couponType == '2'">
      <div class="row">
        <div class="col-12 px-2" v-for="(option, idx) in options" :key="idx">
          <SharedRadio
            v-model="proxy"
            :id="`proxy-${idx}`"
            :value="option.value"
            name="proxy"
          >
            {{ option.text }}
          </SharedRadio>
        </div>
      </div>
    </div>
    <div v-if="showBarcodeInput">
      <input class="search__input mx-auto w-100 mt-3" v-model="barcode" :disa1bled="couponType == '1'"/>
    </div>
    <div>
      <Loading v-if="showLoading" loading-message="載入中..." />
      <div v-else-if="!showLoading">
        <div
          v-html="getModuleConfig('redeem_confirm_page.content')"
          class="p-4 shadow-lg rounded redeem--content"
        ></div>
      </div>
    </div>

    <div
      v-if="
        getModuleConfig('redeem_confirm_page.button_text') ||
        getModuleConfig('redeem_confirm_page.cancel_button_text')
      "
      class="p-3 fixed-bottom bg-white footer"
    >
      <SharedButton class="s-btn-bg-primary mb-2" @click="redeemCoupon" :disabled="!canSubmit">{{
        getModuleConfig("redeem_confirm_page.button_text")
      }}</SharedButton>
      <SharedButton variant="outline-dark" class="s-btn-outline-primary">{{
        getModuleConfig("redeem_confirm_page.cancel_button_text")
      }}</SharedButton>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import couponMixin from "@/mixins/liff/coupon";
import abbottAndApi from "@/apis/liff/v2/proj/abbott-and";
import QRCodeScanner from "@/components/QRCodeScanner";
import SharedRadio from "@/components/DynamicForm/Radio.vue";
import { mapActions } from 'vuex';
import abbottAndMixin from "@/mixins/liff/proj/abbott-and";

export default {
  mixins: [couponMixin, abbottAndMixin],
  components: { Loading, SharedButton, QRCodeScanner, SharedRadio },
  data() {
    return {
      showLoading: false,
      couponCode: "",
      barcode: null,
      options: [],
      proxy: null,
      couponType: null,
      showBarcodeInput: false,
    };
  },

  computed: {
    canSubmit() {
      if (this.couponType == '1' && this.barcode) {
        return true
      }
      if (this.couponType == '2' && this.proxy) {
        const provider = this.options.find((item) => item.value === this.proxy)
        if (provider.verify && this.barcode) {
          return true
        }
        if (!provider.verify) {
          return true
        }
      }
      return false
    }
  },
  async mounted() {
    await this.fetchCoupon();
  },
  watch: {
    proxy: {
      handler: function (val) {
        this.onRadioChange(this.options.find((item) => item.value === val));
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('projAbbottAnd', ['setVerifyCode', 'setStoreCode']),
    onRadioChange(provider) {
      if (!provider) {
        return
      }
      if (provider.verify) {
        this.showBarcodeInput = true;
      }
      this.setStoreCode(provider.value)
    },
    redeemCoupon() {
      this.setVerifyCode(this.barcode)
      this.confirmCoupon();
    },
    async onScan(decodedText) {
      this.barcode = decodedText;
    },
    async fetchCoupon() {
      await abbottAndApi
        .getCouponDetail(this.$route.params.orgCode, {
          coupon_id: this.$route.params.id,
        })
        .then(async (res) => {
          this.couponCode = res.data.data.code;
          if (this.couponCode.includes("BG_H")) {
            this.couponType = "2";
          } else {
            this.couponType = "1";
            this.showBarcodeInput = true;
          }
          if (this.couponType == '2') {
            await this.fetchStoreList();
          }
        });
    },
    async fetchStoreList() {
      await abbottAndApi
        .getEventStoreList(this.$route.params.orgCode)
        .then(async (res) => {
          this.options = res.data.data.map((item) => {
            return {
              text: item.storeName,
              value: item.storeCode,
              verify: item.needVerify,
            };
          });
        });
    },
  },
};
</script>

<style lang="scss">
.redeem {
  &--content {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.search {
  &__input {
    background: #ffffff;
    border-radius: 10px !important;
    border: 1px solid var(--liff-button-color);
    padding: 15px 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;

    &::placeholder {
      letter-spacing: -0.408px;
      color: #b7b7b7;
    }

    &:focus {
      border: 1px solid var(--liff-button-color);
      outline: none;
    }
  }
}
</style>
